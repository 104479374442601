import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BoardOfDirectorsComponent } from './board-of-directors/board-of-directors.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { HomeComponent } from './home/home.component';
import {BaseLayoutComponent} from './base-layout/base-layout.component';

const routes: Routes = [

  {
    path: 'meeting',
    loadChildren: () => import('./modules/meeting/meeting.module').then(m => m.MeetingModule)
  },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'about-us', component: AboutComponent },
      { path: 'directors', component: BoardOfDirectorsComponent },
      { path: 'downloads', component: DownloadsComponent },
      {
        path: 'register',
        loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: 'schools',
        loadChildren: () => import('./modules/schools/schools.module').then(m => m.SchoolsModule)
      },
      { path: 'policies', loadChildren: () => import('./modules/policies/policies.module').then(m => m.PoliciesModule) }
    ]
  },
	{
    path: '**',
    component: ErrorComponent
  },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
