<div class="page-title-area">
	<div class="container">
		<div class="page-title-content">
			<ul>
				<li><a routerLink="/">Home</a></li>
				<li>About Us</li>
			</ul>
			<h2>About Us</h2>
		</div>
	</div>
	<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="about-area pt-5">
	<div class="container">
		<div class="section-title">
			<span class="sub-title">Education for everyone</span>
			<h2>Online Lessons For Remote Learning</h2>
		</div>
	</div>
</div>

<div class="about-area pb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-7 col-md-12">
				<div class="about-content">
					<span class="sub-title">Distance Learning</span>
					<h2>El Shaddai Group of Schools</h2>
					<p>
						El Shaddai Home Schools is a group of virtual home schools that provide high quality, first class
						education to students from the comfort of their homes, by highly trained,
						qualified and experienced educators, using safe and secure technological innovations. El Shaddai
						Home Schools is a fully internet-based school in which classes are held
						online from remote locations. Students will learn from home and teachers will teach from home or an
						office. This strategy is the first of its kind in Jamaica and the world.
					</p>
					<p><strong>Grow your knowledge and your opportunities with thought leadership, training and tools.</strong>
					</p>
					<a routerLink="/" class="link-btn">Explore Learning</a>
				</div>
			</div>
			<div class="col-lg-5 col-md-12">
				<div class="about-video-box">
					<video width="720" height="480" controls>
						<source src="assets/video/eshs_intro.mp4" type="video/mp4">
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="about-area bg-fffaf3 ptb-100">
	<div class="container">
		<div class="feedback-slides-two">
			<div class="row align-items-center">
				<div class="col-12">
					<div class="about-content">
						<h2>Our Vision</h2>
						<p>
							The vision of El Shaddai Home Schools is to be the leading Virtual Home School Institution in
							Jamaica, the region and the world.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="divider2"></div>
	<div class="divider3"></div>
	<div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
	<div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
	<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="about-area bg-f9f9f9 pt-100 pb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-12">
				<div class="about-content text-center">
					<h2>Our Mission</h2>
					<p>
						The mission of El Shaddai Home Schools is to provide first class education and training to
						individuals of
						all ages and cultures worldwide using the prescribed educational standards and policies of each
						jurisdiction/country.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="about-area pt-100 pb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-8 mx-auto">
				<div class="about-content text-center">
					<h3>We stand by our motto “Developing the Whole Man” with our mantra “ALL STUDENTS MUST LEARN”.</h3>
					<a routerLink="/register" class="default-btn">
						<i class="flaticon-user" aria-hidden="true"></i>Get Started Now
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
	<div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
	<div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>