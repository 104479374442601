<div class="page-title-area">
	<div class="container">
		<div class="page-title-content">
			<ul>
				<li><a routerLink="/">Home</a></li>
				<li>About Us</li>
			</ul>
			<h2>Downloadable Documents</h2>
		</div>
	</div>
	<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="features-area pt-5 pb-70">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-12 col-sm-12">
				<div class="events-details-info">
					<h4>Behaviour Contract</h4>
					<div class="btn-box">
						<a href="./assets/docs/elshaddai_behaviour_contract.docx" target="_blank" class="default-btn">
							<i class="mdi mdi-download"></i>Download
						</a>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-12 col-sm-12">
				<div class="events-details-info">
					<h4>Fees & Charges 2023-2024</h4>
					<div class="btn-box">
						<a href="./assets/docs/elshaddai_homeschools_fees_2023-2024.pdf" target="_blank" class="default-btn">
							<i class="mdi mdi-download"></i>Download
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
