import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

	classApplied = false;
	toggleClass() {
		this.classApplied = !this.classApplied;
	}

	get SCHOOL_URL(): string {
		return environment.schoolURL;
	}

}