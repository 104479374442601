<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <a routerLink="/"><h3 class="text-white">El Shaddai Home Schools</h3></a>
          <p>Working to bring significant changes in online-based learning by doing extensive research for course
            curriculum preparation, student engagements, and looking forward to the flexible education!</p>
          <ul class="social-link">
            <li><a href="https://www.facebook.com/elshaddai.homeschools" class="d-block" target="_blank"><i
                  class='bx bxl-facebook'></i></a></li>
            <li><a href="https://twitter.com/ElShaddai_HS" class="d-block" target="_blank"><i
                  class='bx bxl-twitter'></i></a></li>
            <li><a href="https://www.instagram.com/elshaddaihomeschools/" class="d-block" target="_blank"><i
                  class='bx bxl-instagram'></i></a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget pl-5">
          <h3>Our Schools</h3>
          <ul class="footer-links-list">
            <li>
              <a routerLink="/schools/el-shaddai-kinder">El Shaddai Kinder School</a>
            </li>
            <li>
              <a routerLink="/schools/el-shaddai-prep">El Shaddai Preparatory School</a>
            </li>
            <li>
              <a routerLink="/schools/el-shaddai-high">El Shaddai High School</a>
            </li>
            <li>
              <a routerLink="/schools/el-shaddai-evening">El Shaddai Evening & Weekend School</a>
            </li>
            <!-- <li><a href="./assets/docs/el_shaddai_college_broucher.pdf" target="_blank">El Shaddai College</a></li> -->
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Contact Us</h3>
          <ul class="footer-contact-info">
            <li><i class='bx bx-map'></i>Lot 563 Rodney Boulevard, Spanish Town P.O., St. Catherine, Jamaica</li>
            <li><i class='bx bx-phone-call'></i><a href="tel:+18763308325">+1 (876) 330-8325</a></li>
            <li><i class='bx bx-envelope'></i><a
                href="mailto:info@elshaddaihomeschools.com">info@elshaddaihomeschools.com</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Resources</h3>
          <ul class="footer-links-list">
            <li><a routerLink="/downloads">Downloadable Documents</a></li>
            <li><a routerLink="/policies/payment">Payment Policy</a></li>
            <li><a routerLink="/policies/refund">Refund Policy</a></li>
            <li><a routerLink="/policies/school-rules">School Rules</a></li>
          </ul>
        </div>
      </div>
     
    </div>
    <div class="footer-bottom-area">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <p><i class='bx bx-copyright'></i>Copyright © {{ YEAR }} El Shaddai Home Schools.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</footer>