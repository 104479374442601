<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>Board of Directors</li>
      </ul>
      <h2>Board of Directors</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pt-100 pb-70">
  <div class="container">
    <div class="profile-box">
      <div class="row">
        <div class="col-lg-4 col-md-4 mt-4">
          <div class="image">
            <img src="assets/img/directors/egr.png" alt="image">
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="content">
            <h3>Ramocan, Eliud G. <small>MBA, PMP, JP</small></h3>
            <span class="sub-title">Chairman</span>
            <p>
              Director at the Bank of Jamaica (BOJ), Mr. Ramocan has been responsible for managing top
              strategic projects involving
              economic intelligence and data management. He is an enrolled IMF Technical Assistance Expert in
              Balance of Payments Statistics and author of a groundbreaking publication on Remittances
              entitled
              <span class="text-info">Remittances to Jamaica: Findings from a National Survey of Remittance
                Recipients.</span>
              Mr. Ramocan is a recipient of the BOJ Outstanding Award of Excellence in 2013 and an awardee in
              the BOJ Strategic Planning Competitionin 2016. He was also awarded an
              Executive Education certificate in Strategic Management for Regulatory & Enforcement Agencies in
              2013 from Harvard University at John F. Kennedy School of Government
            </p>
            <p>
              Mr. Ramocan holds a BSc. in Computer Science and Economics (Hons.) and an MBA in General
              Management from the University of the West Indies, Mona Campus (UWI). He is currently pursuing
              a Doctorate in Business Administration at the Mona School of Business and Management (MSBM), in
              the field of project management and central banking.
              He is a certified project management professional (PMP) since 2011, an expert trainer for the
              Project Management Institute (PMI) Jamaica Chapter and an adjunct
              lecturer in project management at UWI.
            </p>
            <p>
              He represented Jamaica on the 4th WTO Trade Policy Review of Jamaica in Geneva and has served as
              a member of the Institute of New Economic Thinking
              Young Scholars Initiative, CARIFORUM-EPA Partnership Committee, PIOJ National Working Group on
              International Migration & Development, Jamaica Customs
              Quality Assurance Committee, PMI Jamaica Chapter, American Economic Associationand the
              Oxford/Harvard Cambridge Society of Jamaica. Prior to entering the public service,
              Mr. Ramocan was Director of Finance and Technology for six years at EMCD Group, an education
              technology and motorist service company.
            </p>
            <p>
              A past president of the UWI chapter of the Toastmasters Club and a deacon at the Church of God
              International, Mr. Ramocan engages audiences in Jamaica and across the North America and Europe
              with a particular
              focus on character development and servant leadership.
            </p>
            <p>
              He also serves his country as a Justice of the Peace and enjoys playing the piano and guitar for
              his wife, Kimberly, and their two beautiful children.
            </p>
            <!-- <ul class="info">
                          <li><span>Phone Number:</span> <a href="tel:+44254588689">(+44) -2545 - 88689</a></li>
                          <li><span>Email:</span> <a href="mailto:hello@sarahtaylor.com">hello@sarahtaylor.com</a></li>
                      </ul>
                      <ul class="social-link">
                          <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                          <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                          <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                          <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                      </ul> -->
          </div>
        </div>
      </div>
    </div>

    <div class="profile-box">
      <div class="row pt-4">
        <div class="col-lg-4 col-md-4 mt-4">
          <div class="image">
            <img src="assets/img/directors/ph.png" alt="image">
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="content">
            <h3>Hall, Pedro A.<small>B.A, Dip Ed, JP, Minister of Religion.</small></h3>
            <span class="sub-title">Group Principal</span>
            <p>
              Mr. Pedro Hall is a pastor in the Church of God International, Spanish Town and is married with one
              beautiful daughter. Mr. Hall serves the
              country as a Justice of the Peace for the parish of St. Catherine. With more than half of his life serving
              in the government of Jamaica education system,
              he worked at the Horizon Park Primary and Junior High School and currently at Eltham High School as Guidance
              Counsellor.
            </p>
            <p>
              He also serves as chairman of ABC Early Childhood Development Centre; Seventh Day Baptist Early Childhood
              Development Centre and
              Smith’s Better Learning Preparatory School.
              He served on several youth development committees for the parish of St. Catherine and has been selected to
              serve on the Child Diversion Committee for
              the parish of St. Catherine.
            </p>
            <p>
              Mr. Hall is the owner and operator of OBSE Distributors (formerly Online Books,Stationery & Electronics), a
              distributor of textbooks,
              stationery, office supplies and electronics items.
            </p>
            <p>
              He is a proud graduate of Kingston College, the Mico Teachers College (now Mico University College) and the
              International University of the Caribbean.
              He enjoys helping young people to realize their potential.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-box pt-4">
      <div class="row">
        <div class="col-lg-4 col-md-4 mt-4">
          <div class="image">
            <img src="assets/img/directors/cv.png" alt="image">
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="content">
            <h3>Christie, Vandella L.<small>MSc, BSc, Dip. Ed.</small></h3>
            <span class="sub-title">Communication Director</span>
            <p>
              Ms. Vandella Christie is an Educator who is a trained teacher of Mathematics and she holds a BSc
              in Public Administration as well
              as a MSc in Accounting from the University of the West Indies (UWI), Mona Campus.
            </p>
            <p>
              She taught at her alma mater before pursuing her studies at the (UWI). Ms. Christie tutored and
              lectured Mathematics and Statistics at UWI Mona and the
              Institute of Management and Production (IMP), now University of the Commonwealth Caribbean
              (UCC).
            </p>
            <p>
              She enjoys working with children to help them become and be the best version of themselves.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-box pt-4">
      <div class="row">
        <div class="col-lg-4 col-md-4 mt-4">
          <div class="image">
            <img src="assets/img/directors/sj.png" alt="image">
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="content">
            <h3>Stephenson, Julienne A.<small>M.Ed., B.Ed., Dip. Ed.</small></h3>
            <span class="sub-title">Vice chairman</span>
            <p></p>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-box pt-4">
      <div class="row">
        <div class="col-lg-4 col-md-4 mt-4">
          <div class="image">
            <img src="assets/img/directors/oh.png" alt="image">
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="content">
            <h3>Hall, Oddeth S.<small>B.A.</small></h3>
            <span class="sub-title">Secretary</span>
            <p></p>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-box pt-4">
      <div class="row">
        <div class="col-lg-4 col-md-4 mt-4">
          <div class="image">
            <img src="assets/img/directors/mc.png" alt="image">
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="content">
            <h3>Coleman, Mario N.</h3>
            <!-- <span class="sub-title">Chairman</span> -->
            <p></p>

          </div>
        </div>
      </div>
    </div>



  </div>
</div>