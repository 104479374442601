<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
	<div class="container-fluid">
		<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
			<a class="navbar-brand" routerLink="/">
				<img src="assets/logo/logo.png" alt="logo" width="80" /> <span
					style="font-weight: 700; font-size: x-large;vertical-align:middle;">El Shaddai Home Schools</span>
			</a>
			<button class="navbar-toggler" type="button" (click)="toggleClass()">
				<span class="burger-menu">
					<span class="top-bar"></span>
					<span class="middle-bar"></span>
					<span class="bottom-bar"></span>
				</span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav">
					<li class="nav-item"><a routerLink="/about-us" class="nav-link">About Us</a></li>
					<li class="nav-item"><a routerLink="/directors" class="nav-link">Board of Directors</a></li>
					<li class="nav-item">
						<a href="javascript:void(0)" class="nav-link">
							Schools <i class='bx bx-chevron-down'></i>
						</a>
						<ul class="dropdown-menu">
							<li class="nav-item">
								<a routerLink="/schools/el-shaddai-kinder" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">El Shaddai Kinder
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/schools/el-shaddai-prep" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">El Shaddai Prep
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/schools/el-shaddai-high" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">El Shaddai High
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/schools/el-shaddai-evening" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">El Shaddai Evening & Weekend School
								</a>
							</li>
						</ul>
					</li>
					<li class="nav-item">
						<a href="javascript:void(0)" class="nav-link">
							Resources <i class='bx bx-chevron-down'></i>
						</a>
						<ul class="dropdown-menu">
							<li class="nav-item">
								<a routerLink="/downloads" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">Downloadable Documents
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/policies/payment" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">Payment Policy
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/policies/refund" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">Refund Policy
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/policies/school-rules" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">School Rules
								</a>
							</li>
						</ul>
					</li>
					<li class="nav-item"><a routerLink="/register" class="nav-link">Create Account</a></li>
					<li class="nav-item"><a [href]="'/' + SCHOOL_URL" class="nav-link">Login To School</a></li>
				</ul>
				<ul class="navbar-nav for-responsive">
					<li class="nav-item"><a routerLink="/about-us" class="nav-link">About Us</a>
					</li>
					<li class="nav-item"><a routerLink="/directors" class="nav-link">Board of Directors</a></li>
					<li class="nav-item"><a href="javascript:void(0)" class="nav-link">Schools <i
								class='bx bx-chevron-down'></i></a>
						<ul class="dropdown-menu">
							<li class="nav-item">
								<a routerLink="/schools/el-shaddai-kinder" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">El Shaddai Kinder
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/schools/el-shaddai-prep" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">El Shaddai Prep
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/schools/el-shaddai-high" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">El Shaddai High
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/schools/el-shaddai-evening" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">El Shaddai Evening & Weekend School
								</a>
							</li>
						</ul>
					</li>
					<li class="nav-item">
						<a href="javascript:void(0)" class="nav-link">
							Resources <i class='bx bx-chevron-down'></i>
						</a>
						<ul class="dropdown-menu">
							<li class="nav-item">
								<a routerLink="/downloads" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">Downloadable Documents
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/policies/payment" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">Payment Policy
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/policies/refund" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">Refund Policy
								</a>
							</li>
							<li class="nav-item">
								<a routerLink="/policies/school-rules" class="nav-link" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}">School Rules
								</a>
							</li>
						</ul>
					</li>
					<li class="nav-item"><a routerLink="/register" class="nav-link">Register</a></li>
					<li class="nav-item"><a [href]="'/' + SCHOOL_URL" class="nav-link">Login To School</a></li>
				</ul>
			</div>
			<div class="others-option d-flex align-items-center">
			</div>
		</nav>
	</div>
</div>