<div class="main-banner-area">
	<div class="container-fluid">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="main-banner-content-style-two">
					<h1>El Shaddai Home Schools</h1>
					<p>
            El Shaddai Home School is a fully online institution, where classes are conducted remotely from various locations.
            Students can learn from the comfort of their own homes, while teachers teach from either their homes or offices.
            With this innovative approach to education, students can benefit from the convenience of learning from anywhere, while still receiving a quality education.
					</p>
					<a routerLink="/register" class="default-btn">
						<i class="mdi mdi-lead-pencil" aria-hidden="true"></i> Get started Now
					</a>
				</div>
			</div>
			<div class="col-lg-6 col-md-12">
				<div class="main-banner-image-style-two">
					<img src="assets/img/banner-img4.png" alt="image">
				</div>
			</div>
		</div>
	</div>
	<div class="banner-shape1"><img src="assets/img/banner-shape1.png" alt="image"></div>
	<div class="banner-shape2"><img src="assets/img/banner-shape2.png" alt="image"></div>
	<div class="banner-shape3"><img src="assets/img/banner-shape3.png" alt="image"></div>
	<div class="bulb"><img src="assets/img/bulb.png" alt="image"></div>
</div>

<div class="features-area pt-100 pb-70 bg-fff8f8">
	<div class="container">
		<div class="section-title">
			<span class="sub-title">EDUCATION FOR EVERYONE</span>
			<h2>El Shaddai Group of Schools​</h2>
			<p>El Shaddai Home Schools consists of the following virtual schools</p>
		</div>
		<div class="row justify-content-center">
			<div class="col-lg-4 col-sm-6 col-md-6">
				<div class="features-box" style="height: 19rem">
					<div class="icon">
						<!-- <i class="flaticon-brain-process"></i> -->
						<!-- <i class="bi bi-boxes"></i> -->
						<i class="mdi mdi-shape" aria-hidden="true"></i>
					</div>
					<h3>El Shaddai Kinder</h3>
					<p>
						Leading the charge in building a firm and lasting educational foundation
						for children between the ages of 2 and 6 years old.
					</p>
					<a routerLink="/schools/el-shaddai-kinder" class="link-btn">Click here to learn more</a>
					<div class="back-icon">
						<!-- <i class="flaticon-brain-process"></i> -->
						<!-- <i class="bi bi-boxes"></i> -->
						<i class="mdi mdi-shape" aria-hidden="true"></i>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-6 col-md-6">
				<div class="features-box" style="height: 19rem">
					<div class="icon">
						<!-- <i class="flaticon-shield-1"></i> -->
						<i class="mdi mdi-rocket-launch" aria-hidden="true"></i>
					</div>
					<h3>El Shaddai Prep</h3>
					<p>
						Leading the charge in building on a firm and lasting educational foundation for children
						between the ages of 6 and 12 years old.
					</p>
					<a routerLink="/schools/el-shaddai-prep" class="link-btn">Click here to learn more</a>
					<div class="back-icon">
						<!-- <i class="flaticon-shield-1"></i> -->
						<i class="mdi mdi-rocket-launch" aria-hidden="true"></i>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-6 col-md-6">
				<div class="features-box" style="height: 19rem">
					<div class="icon">
						<!-- <i class="flaticon-world"></i> -->
						<i class="mdi mdi-book-open-page-variant" aria-hidden="true"></i>
					</div>
					<h3>El Shaddai High</h3>
					<p>
						Leading the charge in building on a firm and lasting primary educational foundation for
						children between the ages of 12 and 17 years old.
					</p>
					<a routerLink="/schools/el-shaddai-high" class="link-btn">Click here to learn more</a>
					<div class="back-icon">
						<!-- <i class="flaticon-world"></i> -->
						<i class="mdi mdi-book-open-page-variant" aria-hidden="true"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-lg-4 col-sm-6 col-md-6">
				<div class="features-box">
					<div class="icon">
						<!-- <i class="flaticon-brain-process"></i> -->
						<i class="bi bi-book" aria-hidden="true"></i>
					</div>
					<h3>El Shaddai Evening & Weekend School</h3>
					<p>
						El Shaddai Evening & Weekend School (ESEWS) caters to students
						of early childhood, primary and secondary levels, who need additional
						help. We also specialize in remedial and advanced reading for all age groups.
					</p>
					<a routerLink="/schools/el-shaddai-evening" class="link-btn">Click here to learn more</a>
					<div class="back-icon">
						<!-- <i class="flaticon-brain-process"></i> -->
						<i class="bi bi-book" aria-hidden="true"></i>
					</div>
				</div>
			</div>
			<!-- <div class="col-lg-4 col-sm-6 col-md-6">
				<div class="features-box">
					<div class="icon">
						<i class="flaticon-shield-1"></i>
					</div>
					<h3>El Shaddai College</h3>
					<p>
						El Shaddai College (ESC) will offer a wide range of certificate, diploma, associate &
						first-degree programmes to students 17 years and older.
					</p>
					<a routerLink="/profile-authentication" class="link-btn">Click here to learn more</a>
					<div class="back-icon">
						<i class="flaticon-shield-1"></i>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</div>

<!-- <app-learning-courses></app-learning-courses> -->

<!-- <app-learning-about></app-learning-about> -->
<div class="about-area ptb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="about-image">
					<img src="assets/img/about-img12.png" alt="image">
				</div>
			</div>
			<div class="col-lg-6 col-md-12">
				<div class="about-content">
					<span class="sub-title"></span>
					<h2>Ministry Of Education Approved</h2>
					<p>
						Though officed in Jamaica, El Shaddai Home Schools caters to students and employs teachers from all over the
						world.
						The school complies with all Ministry of Education policies, regulations, guidelines and procedures
						including:
					</p>
					<ul class="features-list">
						<li><span><i class="flaticon-teacher"></i> Expert Instruction</span></li>
						<li><span><i class="bi bi-card-checklist"></i> Detailed record keeping</span></li>
						<li><span><i class="flaticon-agenda"></i> Use of prescribed documents</span></li>
						<li><span><i class="bi bi-file-text"></i> Submission of termly and annual reports</span></li>
						<li><span><i class="flaticon-calendar"></i> Number of school days in the term and year</span></li>
						<li><span><i class="flaticon-world"></i> Observance of national events/functions</span></li>
						<li><span><i class="flaticon-self-growth"></i> Professional development for teachers </span></li>
					</ul>
					<!-- <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a> -->
				</div>
			</div>
		</div>
	</div>
	<div class="bulb"><img src="assets/img/bulb.png" alt="image"></div>
</div>


<!-- <app-learning-view-courses></app-learning-view-courses> -->
<div class="about-area bg-fff8f8">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="about-image">
					<img src="assets/img/strategy.png" alt="image">
				</div>
			</div>
			<div class="col-lg-6 col-md-12">
				<div class="about-content">
					<span class="sub-title">CAREER OPPORTUNITY</span>
					<h2>Interested in becoming an instructor?</h2>
					<p> We are always looking for innovative and motivated individuals with a knack for explaining! See below to
						learn how to join our team.
					</p>
					<a (click)="instructorDetailsModal.show()" class="default-btn clickable"><i class="flaticon-agenda"></i>Learn
						more<span></span></a>
				</div>
			</div>
		</div>
	</div>
	<div class="bulb"><img src="assets/img/bulb.png" alt="image"></div>
</div>

<div class="about-area">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="about-content">
					<span class="sub-title">DISTANCE LEARNING</span>
					<h2>Give Their Limitless Potential Unlimited Access</h2>
					<p>Our flexible teaching also means, if you travel often or need to relocate, you can continue to study
						wherever you go.</p>
				</div>
			</div>
			<div class="col-lg-6 col-md-12">
				<div class="about-image">
					<img src="assets/img/security.png" alt="image">
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Instructor Details Modal -->
<div class="modal fade" bsModal #instructorDetailsModal="bs-modal" [config]="{backdrop: true}" tabindex="-1"
	role="dialog" aria-labelledby="dialog-static-name">
	<div class="modal-dialog modal-dialog-centered  modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h4 id="dialog-static-name" class="modal-title pull-left">Instructor Details</h4>
				<button type="button" class="btn-close close pull-right" aria-label="Close"
					(click)="instructorDetailsModal.hide()">
					<span aria-hidden="true" class="visually-hidden">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-md-12">
						<p>There are currently 3 steps on your journey to joining our team of innovative, motivated, experienced and
							qualified teachers: </p>
						<ol class="mb-0">
							<li>Sign up for an account.</li>
							<li>After logging in, select careers to browse the various positions we have available. Once you have
								found the one that best suits your
								interests click apply.
							</li>
							<li>Your application will then be reviewed. You may login at any time to view the status of your
								application.</li>
						</ol>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="instructorDetailsModal.hide()">
					<i class="mdi mdi-close-circle" aria-hidden="true"></i> Close
				</button>
				<button type="button" class="btn btn-primary" routerLink="/register">
					<i class="mdi mdi-lead-pencil" aria-hidden="true"></i> Register
				</button>
			</div>
		</div>
	</div>
</div>
