import { Component, OnInit } from '@angular/core';
import { APP_VERSION } from 'app-version';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  readonly YEAR = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
    console.log('APP VERISON: v' + APP_VERSION);
  }

}
