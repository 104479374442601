import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StickyNavModule } from 'ng2-sticky-nav';
import { AccordionModule } from "ngx-accordion";
import { ModalModule } from 'ngx-bootstrap/modal';
import { CountUpModule } from 'ngx-countup';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RfxConfig, RFX_CONFIG } from 'ngx-reactive-form-extensions';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { TabsModule } from 'ngx-tabset';
import { ToastrModule } from 'ngx-toastr';
import { AboutComponent } from './about/about.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BoardOfDirectorsComponent } from './board-of-directors/board-of-directors.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { HomeComponent } from './home/home.component';
import { BaseLayoutComponent } from './base-layout/base-layout.component';

const rfxConfig: RfxConfig = {
  showValidationMessages: {
    cssClass: 'text-danger',
    validationTriggerCondition: 'touched'
  },
  changeClassOnError: {
    cssClass: 'is-invalid',
    validationTriggerCondition: 'touched'
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    BoardOfDirectorsComponent,
    DownloadsComponent,
    BaseLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    CountUpModule,
    StickyNavModule,
    TabsModule,
    NgxScrollTopModule,
    LightboxModule,
    AccordionModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [
    { provide: RFX_CONFIG, useValue: rfxConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }